@use '/src/styles/base/_settings.scss' as s;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0!important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.warning{
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    color: red;
    text-indent: 9px;
    
}

.field {
    font-family: 'visbycf_medium';
    margin: 0 1.6em 0 1.6em;

    > :first-child {
        font-size: s.$font-size-descripcion;
        color: gray;
        // text-indent: 9px;
        // padding: 3px 0 2px 0;
        padding: 3px 0 2px 9px;
    }

    >input {
        font-size: s.$font-size-descripcion;
        color: black;
        width: 100%;
        text-indent: 7px;
        border: solid 1px s.$whiteBlue;
        height: 2.4em;
        border-radius: 3px;
    }
    >input:disabled {
        // background: #EBEBE4;
      }
}

@media (min-width:1024px) {

    .field {
        font-family: 'visbycf_medium';
        margin: 0 .2em 0 .2em;
    
        > :first-child {
            font-size: s.$font-size-descripcion;
            color: gray;
            // text-indent: 9px;
            // padding: 3px 0 2px 0;
            padding: 3px 0 2px 9px;
        }
    
        >input {
            font-size: .8em;
            color: black;
            width: 100%;
            text-indent: 7px;
            border: solid 1px s.$whiteBlue;
            height: 2.4em;
            border-radius: 3px;
        }
    }

}