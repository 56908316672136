@use '/src/styles/base/_settings.scss' as s;

.multi_sub {

    div {
        margin-bottom: .6em;
        line-height: 1.2;
    }

    > :nth-child(2) {
        width: 12em !important;
        margin: .6em auto
    }
}

.home_reg_type {
    background: s.$whiteBlusy;
    min-height: 90vh;

    >:nth-child(2) {
        >:nth-child(2) {
            font-family: 'visbycf_demibold';
            font-size: .9em;
            color: s.$darkBlue;
            // display: flex;
            align-items: end;
            justify-content: center;
            margin: 0 auto 1em auto;
            width: 14em;
        }

        >:nth-child(3) {
            font-family: 'visbycf_demibold';
            font-size: .78em;
            width: 17em;
            margin: 0 auto 0 auto;
        }
    }
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 3.6em 0 0 0;
    padding-bottom: .3em;

    >a>button {
        width: 16em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;

        >div {
            padding-top: 3px;
        }
    }
}

.btn_after {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: .5em 0 0 0;
    padding-bottom: 1em;

    >a>button {
        width: 16em;
        height: 2.35em;
        border: none;
        color: black;
        background: s.$whitef2;

        >div {
            padding-top: 3px;
        }
    }
}

@media (min-width:1024px) {

    .home_reg_type {
        min-height: 60vh;
        >:nth-child(2) {
            >:nth-child(2) {
                font-size: 1.5em;
                width: 25em;
            }

            >:nth-child(3) {
                font-family: 'visbycf_medium';
                font-size: 1em;
                width: 50em;
                margin: 0 auto 0 auto;
            }
        }
    }

    .multi_sub {
        div {
            margin-bottom: .6em;
            line-height: 1.2;
        }

        > :nth-child(2) {
            width: 20em !important;
            margin: .6em auto
        }

        > :nth-child(3) {
            width: 25em;
            margin: 0 auto;
        }

    }

    .cont_bb {
        display: flex;
        justify-content: center;
    }

    .btn_continue {
        margin: 3em 1em 0 0;
    }

    .btn_after {
        margin: 3em 0 0 1em;
        padding-bottom: 1em;
    }
}