@use '/src/styles/base/_settings.scss' as s;
@use '/src/styles/fonts/fonts.scss';
@use '/src/styles/base/_mixins'as m;

.cont_accordion_question {
    margin: auto;
    display: flex;
    // border: 1px solid red;
    background-color: s.$whiteBlusy;
    padding-top: 3rem;
    padding-bottom: 3rem;
    
}

.cont_topic {
    margin: auto;
    display: grid;
    max-width: 20rem;
    grid-template-columns: repeat(1, 1fr);

    @include m.tablet {
        max-width: 40rem;
    }

}

.h1_title_big {
    display: grid;
    min-width: 20rem;
    grid-template-columns: 1fr 1rem;
    font-family: 'visbycf_medium';
    color: s.$darkBlue;
    font-size: 1.4rem;
    border-bottom: 2px solid s.$darkBlue;
    align-items: center;
    align-self: stretch;
    padding-bottom: 1rem;

    >img {
        height: 10px;
        width: 18px;
    }

    @include m.tablet {
        min-width: 40rem;
    }
}

.cont_question{
    padding-top: 1rem;
}
.label_question {
    font-size: 1.2em;
    font-family: 'visbycf_demibold';
    color: s.$darkBlue;
    line-height: 1;
    padding: 0;
    
}

.label_aswer {
  font-family: 'visbycf_medium';
  font-size: .85em;
  color: #82858a;
  line-height: 2;
}