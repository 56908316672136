@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;

.header {
    min-height: 10rem;
    background-color: s.$darkBlue;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;

    background-image: url('../../assets/images/backgroundAboutsSuppliers.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 60%;
    // background-position-y: -60%;
}

.title {
    // background-color: green;
    // margin-top: 6rem;
    // margin-bottom: 3rem;
    color: white;
    font-size: s.$font-size-xlarge;
    font-family: 'visbycf_bold';
    margin-bottom: 2rem;

}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 10rem;
    font-size: 17px;
    // background-color: red;
    margin-top: 0px;

    @include m.tablet {
        gap: 2rem;
    }

    @include m.desktop {
        font-size: 20px;

    }

}

.contenedor,
.contenedor_invertido {

    max-width: 90rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    
    @include m.tablet {
        height: 37vh; 
    } 
    
    .backgroud_1,
    .backgroud_2 {
        background-image: url('../../assets/images/Suppliers1.jpg');
        background-size: cover;
        // height: 100%;
        min-width: 50%;
        // height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 45vh; //min(10rem, calc(23rem)); 

        @include m.tablet {
            height: 37vh;
            border-top-right-radius: 3rem;
            border-bottom-right-radius: 3rem;
        }
    }

    .backgroud_2 {
        background-image: url('../../assets/images/Suppliers2.jpg');
        @include m.tablet {
            border-top-right-radius: 0rem;
            border-bottom-right-radius: 0rem;
            border-top-left-radius: 3rem;
            border-bottom-left-radius: 3rem;
        }
        
    }

    >img {
        object-fit: scale-down;
        max-width: 100%;
        z-index: 0;
        margin-top: -2px;
        // border-bottom-right-radius: 3rem;
        // border-radius: 3rem;

        @include m.desktop {
            // border-bottom-left-radius: 3rem;
            
        }
    }



    >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // background-color: black;
        // width: 100%;
        padding: 4rem;
        gap: 1rem;



        >h2 {
            color: s.$darkBlue;
            font-size: s.$font-size-large;
            // text-align: center;
            line-height: 1.5;
        }

        >h4 {
            // max-width: 90%;
            color: s.$darkGray;
            font-size: s.$font-size-medium;
            line-height: 1.5;
        }

        @include m.tablet {
            padding: 2rem;
            // width: 60%;
            >h2 {
                font-size: s.$font-size-medium;
            }
            >h4 {
                font-size: s.$font-size-small + 0.1em;
                // max-width: 70%;
            }

        }

        @include m.tablet-pro {
            padding: 4rem;
            // width: 60%;
            >h2 {
                font-size: s.$font-size-large;
            }
            >h4 {
                font-size: s.$font-size-medium;
                // max-width: 70%;
            }

        }
    }

    @include m.tablet {
        flex-direction: row;

        max-height: 40rem;
        align-items: center;

        >img {
            max-width: 50%;
            object-fit: scale-down;
            max-width: 50%;
            height: max(25rem, 100%);;
            object-fit: cover;
        }
    }

    @include m.tablet-pro {
        align-items: start;
        >div {
            padding-top: 3rem;
            height: 100%;
        }

    }
}

.contenedor_invertido {
    flex-direction: column;

    @include m.tablet {
        flex-direction: row-reverse;
    }
}

.contenedor_formulario{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    
    // width: 30rem;
    // height: 30rem;
    min-width: 95%;
    min-height: 30rem;
    background-color: white;
    // border: 1px solid #ccc;
    background-color: white;
    margin-top: 2rem;
    // margin-bottom: 4rem;

    @include m.tablet {
        min-width: 0%;
        width: s.$tablet;
    }

    // padding-top: 2rem;
    // padding-bottom: 6rem;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(199, 199, 199, 0.41);
    -moz-box-shadow: 0px 0px 15px 2px rgba(199, 199, 199, 0.41);
    box-shadow: 0px 0px 15px 2px rgba(199, 199, 199, 0.41);
}

.contenedor_descripcion {
    max-width: s.$tablet;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 0rem;

    padding-left: 2rem;
    padding-right: 2rem;
    // padding: 2rem;
    text-align: center;
    
    display: flex;
    flex-direction: column;
    gap: 4rem;

    >span {
        color: s.$darkBlue;
        font-size: s.$font-size-medium;
    }

    @include m.tablet {
        // padding: 8rem;
        margin-top: 2rem;
        margin-bottom: -2rem;
        
    }

    > h2 {
        color: s.$darkBlue;
        font-size: s.$font-size-large;
        margin-top: 4rem;
        margin-bottom: 8rem;
        
        @include m.tablet {
            margin-top: 2rem;
        }
    }
}

.contenedor_help {
    

    padding-left: 2rem;
    padding-right: 2rem;
    // margin-top: -1rem;
    margin-bottom: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
    max-width: 40rem;

    >h1 {
        color: s.$darkBlue;
        font-size: s.$font-size-large;
        font-family: 'visbycf_bold';
        // text-align: center;
        // margin-bottom: 3rem;
    }

    >h2, >h3 {
        color: s.$darkBlue;
        font-size: s.$font-size-medium;
        // text-align: center;
    }

    >a>button {
       @include m.button();
    }


    >h3 {
        margin-top: -2rem;
    }
    @include m.tablet {
        margin-top: 0rem;
        
    }
}

.border_radios {
    border-radius: 3rem;
}

.border_radios_left {
    border-bottom-left-radius: 3rem;
    border-top-left-radius: 3rem;
}

.border_radios_right {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}



