@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;


.solo_mobil {
  @include m.tablet {
    display: none;
  }
}

.solo_tablet {
  display: none;
  @include m.tablet {
    display: inline;
  }
}

// .home_but_white {
//   display: none;

//   @include m.tablet {
//     display: inline-block;
//     font-family: 'visbycf_medium';
//     font-size: 1.2em;
//     border-radius: 20px;
//     border: 1px solid s.$darkBlue;
//     background-color: white;
//     color: #34579b;
//     min-width: 10em;
//     min-height: 2.2em;
//   }
// }

.home_but_white {
  display: inline-block;
  font-family: 'visbycf_medium';
  font-size: s.$font-size-medium;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: transparent;
  color: s.$whitexican;
  min-width: 11em;
  min-height: 2.2em;

  
  > img {
    margin-right: 5px;
  }
  @include m.tablet {
    // font-size: s.$font-size-medium;
    border: 1px solid s.$whitexican;
    background-color: s.$whitexican;
    color: #34579b;
  }
}

.home_but_white_invertir {
    border: 1px solid s.$darkBlue;
    background-color: s.$darkBlue;
    color: s.$whitexican;
}

.home_sup_white {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  margin: 1em 0em 1em 0em;
  width: 100%;
  // min-width: 34.91rem;  
  // width: 100%;

  // place-items: end;
  padding-left: 1.5em;
  // padding-right: 1.5em;
  z-index: 2;
  @include m.tablet {
    grid-template-columns: 0.5fr 1.5fr;
    margin-top: 1rem;
  }

  @include m.desktop {
    width: 70rem;
    margin: auto;
    margin-top: 1rem;
    > :first-child {
      padding-left: 0rem !important;
    }
  }

  @include m.desktop-lg {
    width: 80rem;
    margin: auto;
    margin-top: 1rem;
    > :first-child {
      padding-left: 1rem !important;
    }
  }

  > :first-child {
    
    >img {
      max-width: 10.5em;
    }
  }

  > :last-child {
    display: flex;
    align-items: center;
    place-content:end;
    // gap: 1rem;
    width: 100%;

    @include m.tablet {
      place-content: end;
      gap: 3rem;
      padding-right: 1rem;
    }

    @include m.tablet-pro {
      // place-content: space-between;
    }
  }

  > :last-child>a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding-left: 1rem;
    width: 100%;
    color: white;
    font-size: s.$font-size-medium;

    @include m.tablet {
      // justify-content: flex-start;
    }

    > :first-child {
      padding-right: 6px;
      // padding-top: 5px;

      >img {
        height: 1.2em;
        max-width: 2.2em;
      }
    }

    > :nth-child(2) {
      font-family: 'visbycf_medium';
      font-size: s.$font-size-medium;
      color: s.$whitexican;
      // padding-top: 4px;
      // padding-right: 10px;

      @include m.tablet {
        // font-size: 1.2em;
      }
    }
  }
}

.home_sup_services {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  > :last-child {
    display: none;

    @include m.tablet {
      display: inline;
    }
  }
}

.home_sup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: s.$whiteBlusy;
  padding: 2em 0em 1em 0em;
  width: 100%;
  
  > :first-child {
    padding-left: 1.5em;

    >img {
      max-width: 8em;
    }
  }

  > :last-child > a {
    display: flex;
    justify-content: flex-end;
    // padding-right: 1em;
    // max-width: 25rem;
    place-self: center;

    > :first-child {
      padding-right: 6px;
      padding-top: 4px;
      

      >img {
        max-width: 1.2em;
      }
    }

    > :nth-child(2) {
      font-family: 'visbycf_medium';
      color: s.$darkBlue;
      font-size: 1.2em;
      padding-top: 4px;
      padding-right: 10px;
    }
  }

}

@media (min-width:720px) {
  .home_sup_white {
    > :first-child {
      padding-left: 3rem;
    }


    @include m.tablet {
      > :first-child {
        // padding-left: 0rem;
      }
    }
    > :last-child>a {
      > :first-child {
        // padding-top: 8px;

        >img {
          height: 1.5rem;
        }
      }

      // > :nth-child(2) {
      //   font-size: 1.50em;
      // }
    }


  }
}

@media (min-width:1024px) {

  .home_sup_white {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    position: absolute;
    // margin: 1.7em auto 1em auto;
    // width: 80vw;
    left: 0;
    right: 0;

    > :first-child {
      // padding-left: 0em;
      align-self: center;

      >img {
        max-width: 11.5em;
      }
    }

    > :last-child {
      display: flex;
      // justify-content: flex-end;
      max-width: 35rem;
      place-self: end;
      align-items: center;

      > :first-child {
        padding-right: 6px;
        padding-top: 4px;
        
        >img {
          max-width: 1.2em;
        }
      }

      > :nth-child(2) {
        font-family: 'visbycf_medium';
        font-size: .95em;
        color: s.$whiteAesthetic;
        padding: 4px 1em 0 0;
      }
    }
  }



  .home_sup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: s.$whiteBlusy;
    width: 75vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 2em 0 0 0;
    
    > :first-child {
      padding-left: 1.5em;
      

      >img {
        max-width: 10.5em;
      }
    }

    > :last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: 1em;
      align-items: center;

      > :first-child {
        padding-right: 6px;
        padding-top: 4px;
        // background-color: green;
        >img {
          height: 1.2em;
          max-width: 2.2em;
        }
      }

      > :nth-child(2) {
        font-family: 'visbycf_medium';
        font-size: .95em;
        color: s.$darkBlue;
        padding: 4px 1em 0 0;
      }
    }
  }

  // .home_but_white {
  //   display: inline-block;
  //   font-family: 'visbycf_medium';
  //   font-size: s.$font-size-medium;
  //   border-radius: 20px;
  //   border: 1px solid s.$darkBlue;
  //   background-color: white;
  //   color: #34579b;
  //   min-width: 11em;
  //   min-height: 2.2em;

    
  //   > img {
  //     margin-right: 5px;
  //   }
  //   @include m.tablet {
  //     border-color: s.$whitexican;
  //   }
  // }
}


@media (min-width:2560px) {

  .home_sup_white {

    > :first-child {

      >img {
        max-width: 10.5em;
      }
    }

    >:last-child {
      >a>:nth-child(2) {
        font-size: 1.2em;
      }
    }
  }

  .home_but_white {
    font-size: 1.2em;
    border-radius: 45px;
  }


}