@use '/src/styles/base/_settings.scss' as s;

.home_reg_type {
    background: s.$whiteBlusy;
    height: 88vh;

    >:first-child {
        text-align: center;
        width: 12em;
        margin: 0 auto;

        >:first-child {
            font-family: 'visbycf_demibold';
            font-size: .9em;
            color: s.$dilBlue;
            height: 7.5em;
            // display: flex;  
            align-items: end;
            justify-content: center;   
            margin-bottom: 1em;   
        }

        >:last-child {
            font-family: 'visbycf_demibold';
            font-size: .75em;
        }
    }
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 4.5em 0 0 0;
    padding-bottom: 1em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;

        >div {
            padding-top: 3px;
        }
    }
}

@media (min-width:1024px) {

    .home_reg_type {
        height: 60vh;
        
        >:first-child {
            width: 16em;
    
            >:first-child {
                font-size: 1.6em;
                color: s.$dilBlue;
                height: 6em;
            }
    
            >:last-child {
                font-size: .9em;
            }
        }
    }
    
    .btn_continue {
        margin: 2.5em 0 0 0;
    
    }

}