@use '/src/styles/base/_settings.scss' as s;

.accept {
    font-family: 'visbycf_medium';
    font-size: .85em;
    display: flex;
    justify-content: center;

    >input {
        align-self: baseline;
        margin-top: 5px;
    }

    > :last-child {
        width: 18em;
        text-align: center;
        line-height: 1.9;
        color: gray;

    }
}

.home_reg_type{
    background: s.$whiteBlusy;
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 2em 0 0 0;
    padding-bottom: 4em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;
        > div{
            padding-top: 3px;
        }
    }
}

.mandatory{
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    text-align-last: right;
    margin: .5em 3.2em 3.4em 0;
    color:gray;
}

@media (min-width:1024px) {

    .home_reg_type{
        background: s.$whiteBlusy;
        min-height: 75vh;
    }
    .div_1{
        width: 40em;
        margin: 0 auto;
    }

    .div_2{
        width: 40em;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .div_3{
        width:40em;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .mandatory{
        width: 48em;
        margin: 1em auto;
    }
    
    

}