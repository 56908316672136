@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;

.header {
    min-height: 10rem;
    background-color: s.$darkBlue;
    display: flex;
    justify-content: center;
    align-items: end;

    background-image: url('../../assets/images/backgroundAbouts.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 60%;
    // background-position-y: -60%;
}

.title {
    // background-color: green;
    // margin-top: 6rem;
    // margin-bottom: 3rem;
    color: white;
    font-size: s.$font-size-xlarge;
    font-family: 'visbycf_bold';
    margin-bottom: 2rem;

}

.main {
    display: flex;
    flex-direction: column;
    // gap: 10rem;
    font-size: 17px;
    // background-color: red;
    margin-top: 0px;

    @include m.tablet {
        gap: 7rem;
    }

    @include m.desktop {
        font-size: 20px;

    }

}

.contenedor,
.contenedor_invertido {

    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-direction: column;

    >img {
        object-fit: scale-down;
        max-width: 100%;
        z-index: 0;
        margin-top: 0px;
        border-bottom-right-radius: 3rem;
        // border-radius: 3rem;

        @include m.desktop {
            border-bottom-left-radius: 3rem;
            
        }
    }



    >div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        // background-color: black;
        // width: 100%;
        padding: 4rem;
        gap: 1rem;

        >h2 {
            color: s.$darkBlue;
            font-size: s.$font-size-large;
            // text-align: center;
            line-height: 1.5;
        }

        >h4 {
            // max-width: 90%;
            color: s.$darkGray;
            font-size: s.$font-size-medium;
            line-height: 1.5;
        }

        @include m.tablet {
            padding: 2rem;
            // width: 60%;
            >h2 {
                font-size: s.$font-size-medium;
            }
            >h4 {
                font-size: s.$font-size-small + 0.1em;
                // max-width: 70%;
            }

        }

        @include m.tablet-pro {
            padding: 4rem;
            // width: 60%;
            >h2 {
                font-size: s.$font-size-large;
            }
            >h4 {
                font-size: s.$font-size-medium;
                // max-width: 70%;
            }

        }
    }

    @include m.tablet {
        flex-direction: row;

        max-height: 40rem;
        align-items: start;

        >img {
            max-width: 50%;
        }
    }
}

.contenedor_invertido {
    flex-direction: column;

    @include m.tablet {
        flex-direction: row-reverse;
    }
}

.contenedor_descripcion {
    max-width: 80rem;
    margin: auto;
    margin-top: 2rem;
    // margin-bottom: 8rem;
    padding: 2rem;

    text-align: center;
    color: s.$darkGray;
    font-size: s.$font-size-medium;
    display: flex;
    flex-direction: column;
    gap: 4rem;

    >span {
        color: s.$darkBlue;
        font-size: s.$font-size-medium;
    }

    @include m.tablet {
        padding: 8rem;
        margin-top: 0rem;
    }
}

.contenedor_help {
    margin-top: 8rem;
    margin-bottom: 10rem;
    text-align: center;

    >h1 {
        color: s.$darkBlue;
        font-size: s.$font-size-xlarge;
        font-family: 'visbycf_bold';
        // text-align: center;
        margin-bottom: 3rem;
    }

    >h2 {
        color: s.$darkBlue;
        font-size: s.$font-size-large;
        // text-align: center;
    }

    >a>button {
       @include m.button();
    }



    @include m.tablet {
        margin-top: 0rem;
        
    }
}

.border_radios {
    border-radius: 3rem;
}