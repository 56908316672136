@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;


.flex_3 {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 1rem;
    

    @include m.tablet {
        flex-direction: row !important;
        >:last-child {
            margin-left: auto;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 6rem;
    width: 90%;

   
    font-size: s.$font-size-medium;
    font-family: 'visbycf_medium';

    @include m.tablet {
        width: 70%;
    }
}

.form  ul {
    list-style: none;
    padding-left: 0px;

}
.form div {
    display: flex;
    flex-direction: column;
}

.form label {
    font-family: 'visbycf_medium';
    color: s.$darkBlue;
}

.form input,
.form textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    background-color: s.$bg-body;
    
}

.form textarea {
    min-height: 15rem;
}

.form button {
    padding: 5px 80px;
    border: none;
    background-color: s.$darkBlue;
    color: white;
    cursor: pointer;
}

.form button:disabled {
    opacity: 0.5;
}

// .form button:hover {
//     background-color: #0056b3;
// }

span {
    color: red;
    font-size: 0.8rem;
}


.removing {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}
.button_eliminar {
    background-color: transparent !important;
    color: s.$darkBlue !important;

    width: 1rem !important;
    margin: 0 !important;
    padding: 0 !important;

    margin-right: 1rem !important;
    cursor: pointer;
}

.input_file_label {
    display: none;
}

.custom_file_input {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.custom_file_input input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.custom_file_input button {
    background-color:s.$bg-body;
    color: s.$darkBlue;
    font-family: 'visbycf_bold';

    border: 1px solid s.$darkBlue;
    border-radius: 15px;
    padding: 5px 25px;
    // margin-left: 10px;
    cursor: pointer;
}

// .custom_file_input button:hover {
//     background-color: #45a049;
// }