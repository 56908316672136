@use '/src/styles/fonts/fonts.scss';
@use '/src/styles/base/_settings.scss'as s;
html,
body {
    box-sizing: border-box;
    // height: 100vh;
    // width: 100vw;
    background-color: s.$bg-body;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    font-family: 'visbycf_medium', sans-serif;
    margin: 0;
    padding: 0;
}

:root {
    --clr-base: #ff6347;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    text-wrap: balance;
}

p {
    text-wrap: pretty;
}