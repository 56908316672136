@use '/src/styles/base/_settings.scss' as s;


.info_reg_type {
    font-family: 'visbycf_medium';
    text-align: center;
    margin: 2.1em 0 .6em 0;

    >:first-child {
        margin-bottom: .7em;

        >img {
            width: 4.5em;
        }
    }

    > :nth-child(2) {
        color: s.$darkBlue;
        font-size: 1.3em;
    }

    > :last-child {
        font-size: s.$font-size-descripcion;
    }
}

.info_reg_typeB {
    font-family: 'visbycf_medium';
    text-align: center;
    margin: 2.1em 0 .7em 0;

    >:first-child {
        margin-bottom: .7em;

        >img {
            width: 4.5em;
        }
    }

    > :nth-child(2) {
        color: s.$darkBlue;
        font-size: .95em;
    }

    > :last-child {
        font-size: s.$font-size-descripcion;
    }
}


.drop {

    font-family: 'visbycf_medium';
    color: gray !important;
    font-size: s.$font-size-descripcion;
    background-color: white !important;
    border: solid 1px s.$whiteBlue;
    border-radius: 4px;
    display: flex;
    padding: 2px 6px;
    margin: 0 1.5em;
}

.drop_warning {

    font-family: 'visbycf_medium';
    color: gray !important;
    font-size: s.$font-size-descripcion;
    background-color: white !important;
    border: solid 1px red;
    border-radius: 4px;
    display: flex;
    padding: 2px 6px;
    margin: 0 1.5em;
}

.arrow {
    padding: 0px 0px 0 6px;

    >img {
        width: .6em;
        height: .4em;
    }
}

.cont_drop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .6em 0 0 2.5em;
}

.drop_item {
    font-family: 'visbycf_medium';
    color: gray;
    font-size: s.$font-size-descripcion;
    background-color: white;
    display: flex;
    margin: 0 1em;
    text-decoration: none !important;
}

.drop_menu {
    border-radius: 4px;
    border: solid 1px s.$whiteBlue;
    min-width: 5em !important;
    color: gray !important;
    font-size: 1.2em !important;
    padding-top: .2em;
    padding-bottom: .2em;
}

.documents {
    width: 13em;
    margin: 0 auto 0.3em auto;
    line-height: 1.2;
}

.documentsB {
    width: 17em;
    margin: 0 auto 0.2em auto;
    line-height: 1.2;
}

@media (min-width:s.$tablet) {

    .info_reg_type, .info_reg_typeB {
        // margin: -2.1em 0 .6em 0;

        >:first-child {
            margin-bottom: .7em;
    
            >img {
                width: 5.5em;
            }
        }

        > :nth-child(2) {
            color: s.$darkBlue;
            font-size: 1.6em;
        }
    
        > :last-child {
            font-size: .9em;
        }
    }

    .documents, .documentsB {
        width: 33em;
    }
    
}