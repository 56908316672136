@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;

 
.home_testimonio {
    // display: grid; //none;
    background-image: url('../../assets/images/2_home.png');
    background-size: cover;
    padding-top: 3rem;
    font-family: 'visbycf_demibold';
    margin: auto;

    // padding-bottom: 1rem;
    >.grid_testimonio {
        // border:1px solid red;
        // min-height: 40em;
        // display: flex;
        grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
        justify-content: center;

        @include m.tablet {
            // display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
        }

        @include m.desktop {
            width: 80rem;
            margin: auto;
        }
    }

    .p_5 {
        padding-bottom: 3rem;
    }

}

.p_5 {
    text-align: center;
    // margin: auto;
    color: #e2e7ff;
    // padding-top: 3rem;
    // > :first-child {
    font-size: 2em;
    font-family: 'visbycf_demibold';
    margin-bottom: -0.5rem;
  
    // }
  
  }

.p_6 {
    // border: 1px solid red;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    margin: auto;
    color: #e2e7ff;
    display: flex !important;
    place-items: center;
    justify-content: center;
  }

  .cont_testimonio {
    // border: 1px solid green;
    display: grid;
    color: s.$whiteBlusy;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    align-items: center;
    justify-items: center;
    // min-height: 9em;
    max-width: 18rem;
    text-align: center;
    padding-bottom: 6rem;
  
    @include m.tablet {
      max-width: 25rem;
    }
  }


.cont_testimonio_comentario {
    padding-bottom: 1rem;
    font-size: 1.3em;
    line-height: 1.3em;
    font-family: 'visbycf_medium';
    @include m.tablet {
      font-size: 1.4em;
      line-height: 1.2em;
    }
  }
  
  .cont_testimonio_presentacion {
    // border: 1px solid gold;
    display: grid;
    align-items: center;
    grid-template-columns: 5.5rem 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: left;

    @include m.tablet {
        grid-template-columns: 5rem 1fr;
        min-width: 100%;
    }
    
  }
  
  .cont_testimonio_logo {
    padding-left: 2rem;
    grid-area: 1 / 1 / 3 / 2;
    align-items: center;
    text-align: center; 
    >img {
      width: 3rem;
      border-radius: 50%;
      @include m.tablet {
        width: 2.5rem;
      }
    }
  }
  
  .cont_testimonio_name {
    grid-area: 1 / 2 / 2 / 3;
    font-size: 1em;
    font-family: 'visbycf_demibold';
    line-height: 1em;
    // @include m.tablet {
    //   font-size: 1em;
    //   line-height: 0.8em;
    // }
  }
  
  .cont_testimonio_empresa {
    grid-area: 2 / 2 / 3 / 3;
    font-style: italic;
    font-family: 'visbycf_medium';
    font-size: 0.9em;
    line-height: 0.9em;
    margin-bottom: 0.5rem;
    @include m.tablet {
    //   font-size: 0.7em;
    //   line-height: 0.7em;
      margin-bottom: 0rem;
    }
  }

  .parrafo_comillas::before {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 4rem;
    // width: 32px;
    // height: 32px;
    background-image: url('../../assets/images/comillas.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 5px;
    margin-bottom: -1.5rem;
  }