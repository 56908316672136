@use '/src/styles/base/_settings.scss' as s;

.home_reg_type {
    background: s.$whiteBlusy;
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 2em 0 0 0;
    padding-bottom: 4em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;
        > div{
            padding-top: 3px;
        }
    }
}