.custom-confirm-button,
.custom-error-button {
    background-color: #34579B !important; /* Cambia esto al color que desees */
    color: white; /* Color del texto */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .custom-error-button {
    background-color: red !important;
  }

  div:where(.swal2-icon).swal2-success [class^=swal2-success-line][class$=tip]{
    /* width: 2.6625em !important; */
  }

  div:where(.swal2-icon) {
    height: 4em !important;
    width: 4em !important;
  }