// Colors
$darkBlue: #34579B;
$dilBlue: #5179d0;
$darkGray: #44494E;
$redPassion: #E11A37;
$whiteAesthetic: #e2e7ff;
$whitexican: #ffffff;
$whiteBlusy: #f8f9fd;
$whiteBlue: #e4e9ff;
$whitef2: #f2f2f2;
$bg-body : #f8f9fd;
$grey-light: #f0f0f0;
$grey-semi-light: #a09d9d;
$info: #E8E8FE;
$clr-light: #e0e4fb !default;

// media queries
$mobile: 500px;
$tablet: 768px;
$tablet-pro: 1024px;
// $desktop: 1440px;
$desktop: 1260px;
$desktop-lg: 1440px;
$desktop-xl: 1540px;

$pointer: pointer;

$font-size-xsmall: 0.40em;
$font-size-small: 0.70em;
$font-size-medium: 0.95em;
$font-size-large: 1.25em;
$font-size-xlarge: 1.7em;
$font-size-xxlarge: 2.3em;

$font-size-descripcion: 0.8em;