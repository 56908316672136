@use './_settings'as s;

@mixin mobile {
  @media screen and (min-width: s.$mobile) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: s.$tablet) {
    @content;
  }
}

@mixin tablet-pro {
  @media screen and (min-width: s.$tablet-pro) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: s.$desktop) {
    @content;
  }
}

@mixin desktop-lg {
  @media screen and (min-width: s.$desktop-lg) {
    @content;
  }
}

@mixin desktop-xl {
  @media screen and (min-width: s.$desktop-xl) {
    @content;
  }
}

@mixin button($color : s.$darkBlue, $size: s.$font-size-medium,  $background-color : white) {
  display: inline-block;
  font-family: 'visbycf_medium';
  font-size: $size;
  border-radius: 20px;
  border: 1px solid $color;
  background-color:  $background-color;
  color: $color;
  // min-width: 11em;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 2.2em;
}

@mixin scroll-custome {
  ::-webkit-scrollbar {
    width: 8px;
    /* Ancho de la barra de desplazamiento vertical */
    height: 8px;
    border-radius: 4px;
    background-color: transparent;
    /* Color de fondo de la barra de desplazamiento */
  }

  /* Barra de desplazamiento horizontal */
  ::-webkit-scrollbar-horizontal {
    width: 8px;
    /* Ancho de la barra de desplazamiento vertical */
    height: 8px;
    border-radius: 4px;
    background-color: transparent;
    /* Color de fondo de la barra de desplazamiento */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #C1C1C1;
    /* Color de la parte desplazable de la barra de desplazamiento */
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background-color: #797979;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    background-color: #C1C1C1;
    /* Color de la parte desplazable de la barra de desplazamiento */
  }

  ::-webkit-scrollbar-thumb:horizontal:hover {
    border-radius: 4px;
    background-color: #797979;
  }

}