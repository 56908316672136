@use '/src/styles/base/_settings.scss' as s;

.btn_back {

    >button {

        font-family: 'visbycf_medium';
        display: flex;
        color: s.$darkBlue;
        background-color: s.$whiteBlusy;
        border: none;
        margin-left: 1.85em;
        font-size: s.$font-size-descripcion;

        > :first-child>img {
            width: .65em;
            margin: 0.15em 0 0 0;
        }

        > :last-child {
            padding: .055em 0 0 .55em;
        }
    }

}

@media (min-width:1024px) {
    .btn_back {
        width: 72vw;
        margin: 0 auto;
        padding-top: 6em;

    }
}