@use '/src/styles/base/_settings.scss' as s;

.cont_upload {

    >:last-child {
        border-bottom: solid 1px s.$whiteBlue;

        > div {
            margin-bottom: 8px;
        }
    }

    > :first-child {

        display: flex;
        margin: 1em 2em 0 2em;
        height: 4.1em;

        > :first-child {
            width: 100%;

            > :first-child {
                font-family: 'visbycf_demibold';
                font-size: .95em;
            }

            > :last-child {
                font-family: 'visbycf_medium';
                font-size: s.$font-size-descripcion;
                color: gray;
            }
        }

        > :last-child {

            >div {
                writing-mode: vertical-rl;

                >img {
                    cursor: pointer;
                    width: .85em;
                }
            }
        }
    }
}
.ok, .warning{
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    color: red !important;
    text-indent: 9px;
    
}

.ok {
    color: gray !important;
}
.documents {
    display: flex;
    margin: -1em 2em 1.3em 2em;

    >div {
        display: flex;
        width: 100%;
        border: 1px solid gray;
        border-radius: 6px;
        padding: 1px 5px 0px 8px;

        >:first-child {

            >img {
                width: 1em;
                vertical-align: baseline;
            }
        }

        >:nth-child(2) {
            text-indent: .5em;
            font-family: 'visbycf_medium';
            font-size: s.$font-size-descripcion;
            color: gray;
            place-self: center;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        >:last-child {

            >img {
                width: 1em;
                padding-bottom: 4px;
            }
        }

    }
}

@media (min-width:1024px) {

    .cont_upload {

        > :first-child {
            height: 2.5em;

            > :first-child {
                display: flex;

                >:first-child {
                    width: 15em;
                }
            }
        }
    }

    .documents {
        margin: -.8em 2em 1.3em 2em;
    }
}