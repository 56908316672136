@use '/src/styles/base/_settings.scss' as s;


.home_reg_type {
    background: s.$whiteBlusy;
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 2em 0 0 0;
    padding-bottom: 4em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;

        >div {
            padding-top: 3px;
        }
    }
}

.mandatory {
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    text-align-last: right;
    margin: .5em 3.2em 3.4em 0;
    color: gray;
}

@media (min-width:1024px) {

    .home_reg_type {
        background: s.$whiteBlusy;
        min-height: 65vh;
    }

    .div_1 {
        width: 26em;
        margin: 0 auto;
    }

    .mandatory {
        width: 31em;
        margin: .5em auto 3.4em auto;
    }

    .accept {
        font-size: s.$font-size-descripcion;
        display: flex;
        justify-content: center;

        >input {
            align-self: baseline;
            margin-top: 5px;
        }

        > :last-child {
            display: contents;
            width: 19em;
            text-align: center;
            line-height: 1.9;
            color: gray;

            >:first-child {
                margin: 0 .5em;
            }

            >div>a {
                color: s.$dilBlue  !important;
            }
        }
    }

}