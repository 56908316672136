@use '/src/styles/base/_settings.scss' as s;

.card_opt {
    margin: 0 1.2em .5em 1.2em;

    > :first-child {
        box-shadow: 4px 3px 5px -4px rgb(194 194 194 / 40%);
        background: white;
        border: solid #eaeefe 1px;
        border-radius: 8px;

    }
}

.desc_body {
    height: 2.8em;
    display: flex;
    border-radius: 8px;
    cursor:pointer;

    > :first-child {
        width: 2em;
        text-align: center;

        >img {
            padding-top: 8px;
            width: .7em;
        }

    }

    > :last-child {
        font-family: 'visbycf_medium';
        font-size: .95em;
        line-height: 1.2;
        padding: .4em 0 .2em 0;

        > :first-child {
            font-size: .75em;
            color: darkgray;
        }
    }


}

.acc_body {
    margin: 0.8em 0.8em 0.5em 1.1em;
    background: #eff1fe;
    font-family: 'visbycf_medium';
    padding-bottom: .8em;

    > :first-child {
        font-size: s.$font-size-descripcion;
        color: s.$darkBlue;
        text-indent: 2.2em;
        padding: .4em 0 .4em 0;

    }

    .desc_req {
        display: flex;
        height: 1.2em;

        >img {
            height: .5em;
            width: .6em;
            margin: 0 .5em 0 1.6em;
            align-self: center;
        }

        >div {
            font-size: .7em;
        }
    }
}

.acc_main {

    > :last-child {

        > :first-child> :first-child {

            >:first-child>img {
                padding-bottom: 9px;
            }

            >div {
                margin-top: 5px;

            }
        }


        // > :last-child {
        //     display: none;
        // }
    }
}

@media (min-width:s.$tablet) {


    .card_opt {
        width: 50em;
        margin: 0 auto .5em auto;
        display: flex;
        height: 3.6em;
        padding-left: 14em;

        > :first-child {
            width: 25em;
            margin: 0 0;
        }
    }

    .desc_body {
        height: 3.6em;

        > :first-child {
            >img {
                padding-top: 20px;
                width: .8em;
            }
        }

        > :last-child {
            font-family: 'visbycf_medium';
            font-size: 1.0em;

            > :first-child {
                font-size: s.$font-size-descripcion;
                color: darkgray;
                padding: 5px 0 0 0;
            }
        }
    }

    .acc_body {
        height: max-content;
        margin: 0em -5.6em 0em 0.5em;
        padding: 0em 2em 1em 0;
        width : 16em;
    
        > :first-child {
            font-size: s.$font-size-descripcion;
            color: s.$darkBlue;
            text-indent: 2.2em;
            padding: .4em 0 .4em 0;
    
        }
    
        .desc_req {
            display: flex;
            height: 1.2em;
    
            >img {
                height: .5em;
                width: .6em;
                margin: 0 .5em 0 1.6em;
                align-self: center;
            }
    
            >div {
                font-size: .7em;
            }
        }
    }

    .acc_main {

        > :last-child {
    
            > :first-child> :first-child {
    
                >:first-child>img {
                    padding-bottom: 0px;
                    padding-top: 0;
                }
    
                >div {
                    margin-top: 3px;
    
                }
            }
        }
    }

}