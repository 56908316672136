@use '/src/styles/base/_settings.scss' as s;

.home_reg_type {
    background: s.$whiteBlusy;
}

.desc{
    width: 15em;
    line-height: 1.2;
}

.container_upload{
    margin: 4.2em 0 3.5em 0;

    > :last-child{
        margin-bottom: 5px;
    }
}

.btn_continue, .btn_disabled {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 2em 0 0 0;
    padding-bottom: 1em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;

        >div {
            padding-top: 3px;
        }

    }
}

.warning{
    font-family: 'visbycf_medium';
    font-size: .75em;
    color: red;
    text-indent: 9px;
    text-align: right;
}

.btn_disabled > a >button  {
    background: gray !important;
} 

.btn_after {
    text-align: center;
    padding-bottom: 4em;

    >a {
        >button {
            border: none;
            background: s.$whiteBlusy;
            text-decoration: underline;
            color: s.$dilBlue;
            font-family: 'visbycf_demibold';
            font-size: .95em;
        }
    }
}


@media (min-width:1024px) {

    .container_upload{
        width: 40em;
        margin: 4em auto;
    }
    
    

}