@use '/src/styles/base/_settings.scss' as s;


.warning {
    font-family: 'visbycf_medium';
    font-size: .7em;
    color: red;
    text-indent: 9px;
    text-align: center;
}


.link {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: s.$dilBlue;
    text-decoration: underline;
    cursor: pointer;
}

.modal_body{
    font-family: 'visbycf_medium';
        font-size: s.$font-size-descripcion;
        color: gray;
}
.modal_dialog>:first-child {

    >:first-child {
        border-radius: 0;
    }

}

.modal_header {
    flex-flow: column;
    border-bottom: none !important;

    >:first-child {
        font-family: 'visbycf_demibold';
        font-size: 1em;
        order: 2;
        margin-top: 1em;
    }

    >:last-child {
        order: 1;
    }

}
.home_reg_type{
    background: s.$whiteBlusy;
}

.accept {
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    display: flex;
    justify-content: center;

    >input {
        align-self: baseline;
        margin-top: 2px;
    }

    > :last-child {
        width: 19em;
        text-align: center;
        // line-height: 1.9;
        color: gray;

        >div>a {
            color: s.$dilBlue  !important;
        }
    }
}

.mandatory{
    font-family: 'visbycf_medium';
    font-size: s.$font-size-descripcion;
    text-align-last: right;
    margin: .5em 3.2em 3.4em 0;
    color:gray;
}

.btn_continue {
    text-align: center;
    font-family: 'visbycf_demibold';
    font-size: .85em;
    margin: 2em 0 0 0;
    padding-bottom: 4em;

    >a>button {
        width: 10em;
        height: 2.35em;
        border: none;
        color: white;
        background: s.$dilBlue;
        > div{
            padding-top: 3px;
        }
    }
}

@media (min-width:1024px) {

    .div_1{
        width: 38em;
        margin: 0 auto;
    }

    .div_2{
        width: 38em;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .div_3{
        width: 38em;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    

    .mandatory{
        width: 45em;
        margin: 1em auto;
    }
    
    

}