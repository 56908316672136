@font-face {
    font-family: 'visbycf_bold';
    src: local('visbycf_bold'), url('./visbycf-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'visbycf_demibold';
    src: local('visbycf_demibold'), url('./visbycf-demibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'visbycf_heavy';
    src: local('visbycf_heavy'), url('./visbycf-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'visbycf_medium';
    src: local('visbycf_medium'), url('./visbycf-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }