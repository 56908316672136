.home_but_primary {
    font-family: 'visbycf_medium';
    font-size: .95em;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #34579b;
    background-color: white;
    color: #34579b;
    min-width: 10.5em;
    min-height: 2.6em;
}

.home_but_blue_desk {
    display: none;
}

.home_but_blue {
    font-family: 'visbycf_medium';
    font-size: .95em;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    min-width: 12em;
    min-height: 2.7em;
}

@media (min-width:1024px) {

    .home_but_primary {
        min-width: 13.5em;
        min-height: 3em;
    }

    .home_but_blue_desk {
        display: inline-block;
        font-family: 'visbycf_medium';
        border-radius: 25px;
        border: 1px solid white;
        background-color: transparent;
        color: white;
        font-size: .9em;
        min-width: 13em;
        min-height: 2.2em;

    }

    .home_cont_but,
    .home_cont_but_b {
        display: none;
    }
}