@use '/src/styles/base/_settings.scss' as s;
// @use '/src/styles/fonts/fonts.scss';

.whatsAppButton {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    position: fixed;
    align-items: center;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
    >div>img {
        width: 3em;
    }
}

.whatsAppButtonWithoutCookie {
    display: flex;

    position: fixed;
    bottom: 9em;
    right: 1em;

    >a>div>img {
        width: 2em;
    }
}


.help_bubble {
    position: relative;
    display: inline-block;
    padding-right: 0.5rem;
}

.help_text {
    background-color: #f9f9f9;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: sticky;
    z-index: 2;
    width: 200px;
    max-width: 80vw;
    top: 20px;
    margin-left: 20px;
    transition: opacity 0.3s;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    color: s.$darkGray;
    font-family: 'visbycf_medium';
    font-size: .9em;
}

.help_text::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translateY(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent #f9f9f9;
}

// .help_bubble {
//     background-color: $whitexican;
//     margin-right: 0.5rem;
//     padding: 0.5rem;
//     border-radius: 0.5rem;
//     box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
// }

// .help_text::after {
//     content: '';
//   position: absolute;
//   top: 50%;
//   left: 71%;
//   margin-top: -8px;
//   border-width: 8px;
//   border-style: solid;
//   border-color: transparent transparent transparent #f9f9f9;

//   }

// .help_text {
//     color: $darkGray;
//     font-family: 'visbycf_medium';
//     font-size: .9em;

//     transition: opacity 0.3s;
// }

@media (min-width:768px) {

    .whatsAppButton {
        bottom: 1em;
        right: 3em;
    }

    .whatsAppButtonWithoutCookie {
        display: flex;
        position: fixed;
        bottom: 4.2em;
        right: 1em;

        >a>div>img {
            width: 12em;
        }
    }
}