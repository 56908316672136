@use '/src/styles/base/_settings.scss' as s;


.home_4 {
  // max-height: 90vh;

  > :first-child {
    //contact
    margin: 0 2em 0 4em;

    > :first-child {
      text-align: center;
      margin: 4em 2.5em 5.5em auto;

      >img {
        max-width: 11.5em;
      }
    }

    > :last-child {
      text-align: center;
      margin-top: 4em;

      >div {
        font-family: 'visbycf_medium';
        font-size: s.$font-size-descripcion;
        margin: 3em 2.2em 3em auto;
      }
    }
  }
}


.contac_info {

  >div {
    font-family: 'visbycf_medium';
    font-size: .95em;
    color: s.$darkBlue;
    line-height: 1.4;

    >a {
      color: #34579B;
      text-decoration: none;
    }

    >a:hover {
      color: s.$darkBlue;
    }
  }

  > :first-child {
    font-family: 'visbycf_demibold';
    font-size: 1em;
    color: s.$darkBlue;
    margin: 1.5em 0;
  }
}

.social_info>div {
  font-family: 'visbycf_medium';
  font-size: .9em;
  display: flex;
  justify-content: center;
  margin: 0em 0 .8em 1em;

  >div>img {
    max-width: 2em;
  }

  >a {
    color: black;
    text-decoration: none;

    >div {
      padding-top: 5px;
    }
  }

  > :last-child>a {
    min-width: 5em;
    padding-top: 4px;
  }
}

.pd1 {
  margin-right: 0.2em;
}

.copy1 {
  font-family: 'visbycf_medium';
  font-size: .7em;
  margin: 2rem auto;
  text-align: center;
  padding-bottom: 5em;
}

.social_infob {
  display: none;
}

@media (min-width:1024px) {

  .home_4 {
    height: auto;
    max-width: 80em;
    margin: auto;

    > :first-child {
      //contact
      margin: 0 2em 2em 4em;
      display: flex;

      > :first-child {
        text-align: center;
        margin: 1.5em 4.5em 5.5em 5em;

        >img {
          max-width: 10.5em;
        }
      }

      > :last-child {
        display: none;
      }
    }
  }

  .contac_info {
    width: 100%;

    >div {
      font-family: 'visbycf_medium';
      font-size: .95em;
      color: s.$darkBlue;
      line-height: 1.4;
    }

    > :first-child {
      font-family: 'visbycf_demibold';
      font-size: 1em;
      color: s.$darkBlue;
      margin: 1.5em 0 1em 0;
    }
  }

  .social_infob {
    font-family: 'visbycf_medium';
    font-size: .9em;
    display: block;
    text-align: center;
    margin-bottom: 1em;
  }

  .ssmedia {
    display: flex;
    justify-content: center;

    >div {
      margin: 0 1em
    }

    ;
  }

  .copy1 {
    font-size: .9em;
    margin: 1em 0 3em auto;
    text-align: center;
    padding-bottom: 0em;
  }
}