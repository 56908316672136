@use '/src/styles/base/_settings.scss'as s;
@use '/src/styles/base/_mixins'as m;
// @import '/src/styles/fonts/fonts.scss';


.home_1 {
  background-image: url('../../assets/images/backgroundMobileV4.jpg');
  // background-image: linear-gradient(to top, rgba(255, 255, 255, 1), transparent), url('../../assets/images/backgroundTV1500pxV2.jpg');
  // background-image: url('../../assets/images/backgroundMobile480pxV2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: center;
  // background-position-y: -1%;
  background-position-x: 30%;

  height: auto;
  padding-bottom: 2rem;

  >div {
    @include m.desktop {
      width: 80rem;
      margin: auto;
      height: 35em;
    }
  }

  @include m.tablet {
    background-image: url('../../assets/images/backgroundDesktopV3.jpg');
    background-position-x: 50%;
    background-position-y: 10%;
    height: auto;
    background-image: url('../../assets/images/backgroundDesktopV3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

}

.home_cont_p {

  min-height: 25em;
  margin: 0em 1.1em 0em 1.5em;
  padding: 15em 0em 2em 0em;
  color: s.$whitexican;

  > :first-child{
    margin: 1.5em 0 0 0;
    justify-items: center;
    display: grid;
  }

  > :nth-child(2) {
    margin: 1em 0 0 0;
    padding-right: 5rem;
    font-family: 'visbycf_bold';
    font-size: s.$font-size-xlarge;
    line-height: 1.3;
    color: s.$darkBlue;
  }

  > :nth-child(3) {
    margin: 1em 3em 0 0;
    font-family: 'visbycf_medium';
    font-size: s.$font-size-large;
    line-height: 1.3;
    color: s.$darkBlue;
  }

  @include m.tablet {
    margin: 0em 1.1em 0em 4.5em;
    padding: 3em 0em 2em 0em;
    color: s.$whitexican;

    > :first-child{
        display: none;
    }
    > :nth-child(2) {
      margin: 3.8em 0 0 0;
      // font-family: "visbycf_bold";
      font-size: s.$font-size-xlarge;
      // line-height: 1.3;
      max-width: 16em;
    }

    >:nth-child(3) {
      max-width: 16em;
      font-size: s.$font-size-large;
    }

    > :last-child {
      margin: 1em 3em 0 0;
      font-family: 'visbycf_medium';
      font-size: 1.35em;
      line-height: 1.3;
      color: s.$whiteAesthetic;

      display: none;
    }
  }

  @include m.desktop {
    margin: 0em 1.1em 0em 6em;
  }

  @include m.desktop-lg {
    // margin: 0em 1.1em 0em 6em;
    margin: 0em 1.1em 0em 3em;
  }

  @include m.desktop-xl {
    // margin: 0em 1.1em 0em 4.5em;

    // margin: 0em 1.1em 0em 9.5em;

    > :first-child {
      // font-size: 2.5em;
    }

    >:nth-child(2) {
      // font-size: 2em;
    }
  }
}

.home_cont_but,
.home_cont_but_c {
  text-align: center;
  margin: 1.5em 0 1em 0;
  background-color:red;
}

.home_cont_but {
  @include m.tablet {
    display: none;
  }
}

.home_cont_but_desk {
  text-align: center;
  margin: 0 0 0 0;
  padding-bottom: 3rem;
  padding-top: 3rem;
  background-color: s.$whiteBlusy;

  // border: 1px solid red;
}


.home_cont_but_b {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
  margin: .5em 0 .55em 0;

  @include m.tablet {
    text-align: left;
    flex-direction: row;
    // display: none;
    margin: 0em 1.1em 0em 4.5em;
  }

  @include m.desktop {
    margin: 0em 1.1em 0em 6em;
  }

  @include m.desktop-lg {
    margin: 0em 1.1em 0em 3em;
  }
}



.home_but_white {
  font-family: 'visbycf_medium';
  font-size: s.$font-size-medium;
  ;
  border-radius: 25px;
  border: 1px solid s.$darkBlue;
  background-color: white;
  // background-color: s.$darkBlue;
  color: s.$darkBlue;
  min-width: 12em;
  min-height: 2.8em;
}

.home_but_blue,
.home_but_blue_b {
  font-family: 'visbycf_medium';
  font-size: .95em;
  border-radius: 25px;
  border: 1px solid s.$darkBlue;
  background-color: s.$darkBlue;
  // background-color: transparent;
  color: white;
  min-width: 12em;
  min-height: 3em;
}

.home_but_blue_b {
  background-color: white;
  color: s.$darkBlue;
}
.home_but_blue_desk {
  display: none;
}


.home_but_primary,
.home_but_transparent {
  font-family: 'visbycf_medium';
  font-size: .95em;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid s.$darkBlue;
  background-color: white;
  color: #34579b;
  min-width: 10.5em;
  min-height: 2.6em;
}

.home_but_transparent {
  background-color: transparent;
  color: white;
  border-color: white;
  min-width: 11.5em;
}
.home_2,
.section_ofertas {
  min-height: 35em;
  background-color: s.$whiteBlusy;

}

.home_2b {
  min-height: 50em;
  background-color: s.$whiteBlusy;
}

.home_5>div {
  // min-height: 35em;
  background-color: s.$whiteBlusy;
  display: grid;
  padding: 2rem;
  // border: 1px solid red;

}

.home_6,
.home_7 {
  margin: auto;
  // display: flex;
  // border: 1px solid red;
  background-color: s.$whiteBlusy;

  @include m.tablet {}
}

.home_7 {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  @include m.tablet {}
}

.h2_title {
  max-width: 14em;
  margin: 0 auto 0 auto;
  padding: 3.5em 0 3.5em 0;
  text-align: center;

  > :first-child {
    font-size: 1.45em;
    color: s.$darkBlue;
    font-family: 'visbycf_bold';
    line-height: 1;
  }

  > :last-child {
    font-size: .9em;
    font-family: 'visbycf_medium';
    padding-top: 1px;
    line-height: 1.6;
    color: s.$darkBlue;
  }

}




.envio_mexico {
  max-width: 80%;
  // min-height: 20em;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 0rem;
  padding-bottom: 4rem;

  >div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    place-content: end;
    margin: auto;
    font-size: s.$font-size-large;

    >p {
      max-width: 10rem;
    }

    >p>span {
      color: s.$darkBlue;
      font-family: 'visbycf_bold';
    }

    >img {
      width: 60%;
      object-fit: scale-down;
    }
  }

  @include m.tablet {
    min-height: 20em;
    padding-bottom: 5rem;

    >div {
      flex-direction: row;

      >div {
        >:first-child {
          // max-width: 10rem;
        }
      }
    }
  }
}

.grid_1 {
  margin: auto;
  display: grid;
  // max-width: 16em;
  // min-height: 20em;
  grid-template-columns: repeat(3, 1fr);
  place-content: space-evenly;
  align-items: center;
  // grid-template-rows: repeat(2, 1fr);

  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // place-content: space-evenly;

  >div {
    font-size: .7em;
    text-align: center;
    font-family: 'visbycf_demibold';
    color: black;

    >:last-child {
      max-width: 8.5em;
      margin: auto;
    }
  }

  >div> :first-child>img {
    max-width: 5em;
    padding-bottom: 2.3em;
  }

  >:last-child {

    >:first-child>img {
      min-width: 6em;
      padding-bottom: 1.3em;
    }
  }

  @include m.tablet {
    max-width: 55em;
    min-height: 5em;


    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
    // grid-template-rows: repeat(1, 1fr);
    // place-content: center;

    >div {
      display: flex;
      flex-direction: column;

      font-size: 1em;
      font-family: 'visbycf_medium';

      >:last-child {
        max-width: 8.5em;
      }
    }

    >div> :first-child>img {
      max-width: 10em;
      padding-bottom: 3em;
    }

    >:last-child {

      >:first-child>img {
        min-width: 7em;
        padding-bottom: 2em;
      }
    }

  }

}

.flex_2 {
  display: flex;
  flex-direction: column;
  padding-top: 3em;
  align-items: center;
}


.home_3,
.home_3_b {
  // border: 1px solid red;
  background-image: url('../../assets/images/2_home.png');
  background-size: cover;
  // padding-top: 3rem;
  // padding-bottom: 3rem;

  >div {
    min-height: 29em;
    justify-content: center;
  }

  .grid_4 {
    @include m.tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }

    @include m.desktop {
      width: 80rem;
      margin: auto;
    }

  }
}

.home_3_b > div {
  min-height: auto;
}

.p_3 {
  // border: 1px solid red;
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-width: 11.5em;
  text-align: center;
  margin: auto;
  color: #e2e7ff;

  @include m.tablet {
    max-width: 25em;
  }

  > :first-child {
    font-size: 2em;
    font-family: 'visbycf_demibold';

    @include m.tablet {
      font-size: 3em;
    }
  }

  > :last-child {
    font-family: 'visbycf_medium';
    font-size: .9em;

    @include m.tablet {
      font-size: 1.5em;
    }
  }
}

.pt_4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.p_4,
.p_7 {
  text-align: center;
  color: #e2e7ff;

  > :first-child {
    font-size: 1.5em;
    font-family: 'visbycf_demibold';
    margin-bottom: -0.5rem;

    @include m.tablet {
      font-size: 2em;
    }

  }

  > :nth-child(2) {
    font-family: 'visbycf_medium';
    font-size: 1em;

    @include m.tablet {
      font-size: 1.2em;
    }
  }
}

.p_large {
  padding: 2rem 7rem;
  max-width: s.$tablet;
  text-align: center;
  margin: auto;
  > :first-child
  {
    font-size: 0.9em;
    font-family: 'visbycf_medium';
    color: white;
  }
}

.p_7 {
  > :first-child {
    font-size: 1em;
    font-family: 'visbycf_medium';

    @include m.tablet {
      font-size: 1.2em;
    }

  }

  > :nth-child(2) {
    font-family: 'visbycf_demibold';
    font-size: 1.5em;

    @include m.tablet {
      font-size: 2em;
    }
  }
}

.p_5 {
  text-align: center;
  // margin: auto;
  color: #e2e7ff;
  // padding-top: 3rem;
  // > :first-child {
  font-size: 2em;
  font-family: 'visbycf_demibold';
  margin-bottom: -0.5rem;

  // }

}

.p_6 {
  // border: 1px solid red;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  margin: auto;
  color: #e2e7ff;
}

.h4_title {
  text-align: center;
  font-size: .95em;
  font-family: 'visbycf_medium';
  color: s.$darkBlue;

  > :first-child {
    margin: 0 auto 0em auto;
    padding-top: 4em;
    max-width: 15em;
    font-size: 1em;
  }

  > :last-child {
    margin: .7em auto 2em auto;
    max-width: 14em;
    font-size: s.$font-size-descripcion;
    color: black;

    >span {
      color: s.$redPassion;
    }
  }
}

.h1_title,
.h1_title_big {
  text-align: center;
  font-size: 1.2em;
  font-family: 'visbycf_medium';
  color: s.$darkBlue;
}

.h1_title_big {
  font-size: 1.7rem;
}

.perC1 {
  width: 4em
}

.perC2 {
  width: 4em
}

.perC3 {
  width: 4em
}

.perC4 {
  width: 4em
}

.perC5 {
  width: 4em
}

.lineGray {
  width: 7em;
  border: solid .1px #e8ecfe;
}

.text1 {
  font-family: 'visbycf_bold';
  font-size: .7em;
  width: 11em;
  padding: 1em 0 1em 0;
}

.text2 {
  font-family: 'visbycf_medium';
  font-size: .69em;
  color: #82858a;
  width: 13em;
  padding: .5em 0 0 0;
}


.grid_2 {
  margin: auto;
  display: grid;
  width: 20em;
  height: 35em;
  grid-template-areas:
    "head head2"
    "middle middle2"
    "single single";

  div {
    text-align: center;
    margin: auto;
  }
}

.grid_question {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  // max-width: 200rem;
  // max-height: 10rem;
  margin: auto;

  >div {
    margin: auto;
    // border: 1px solid red;


    >img {
      // max-width: 9rem;
      cursor: pointer;

      @include m.tablet {
        max-width: 12rem;
        height: 25rem;
      }
    }
  }

  @include m.tablet {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0rem;
    max-width: 50rem;
    max-width: 100dvw;
  }
}


.a {
  grid-area: head;
  margin-top: 8px !important;
}

.b {
  grid-area: head2;
  margin-top: 5px !important;

  >:last-child {
    width: 11em;
  }
}

.c {
  grid-area: middle;
  margin-top: 8px !important;
}

.d {
  grid-area: middle2;
  margin-top: 8px !important;

  >:nth-child(2) {
    width: 7em;
  }
}

.e {
  grid-area: single;
  margin-top: 8px !important;

  >:nth-child(2) {
    width: 7em;
  }
}


@media (min-width:s.$desktop) {


  .home_1 {
    // height: 50vh;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    // padding-bottom: 30em;
  }
}

.descripcion_titulo {
  // max-width: 16em;
}




@media (min-width:1023px) {


  .home_2 {
    min-height: 33em;

  }

  .h2_title {
    max-width: 35em;
    padding: 6.3em 0 4.5em 0;

    > :first-child {
      font-size: 2em;
    }

    > :last-child {
      font-size: 1.39em;
    }

  }


  .grid_2 {
    display: flex;
    width: 63em;
    max-height: 20rem;
    grid-template-areas: none;

    div {

      text-align: center;
      margin: 1.1em auto -3em auto;
    }
  }


  .h4_title {
    color: s.$darkBlue;

    > :first-child {
      margin: 0 auto 0em auto;
      padding-top: 4em;
      max-width: 33em;
      font-size: 1.5em;
    }

    > :last-child {
      margin: 0 auto 2em auto;
      max-width: 30em;
      font-size: 1.3em;

      >span {
        color: s.$redPassion;
      }
    }
  }

  .lineGray {
    width: 10em;
    border: solid .1px #e8ecfe;
    margin-top: .5em !important;
  }

  .text1 {
    font-family: 'visbycf_bold';
    font-size: 1em;
    width: 11em;
    padding: 0em 0 0em 0;
    margin-bottom: -40px;
    height: 10em;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .text2 {
    font-family: 'visbycf_medium';
    font-size: .85em;
    color: #82858a;
    width: 15em;
    padding: .5em 0 0 0;
    margin-top: 4em !important;
  }

  .home_but_primary {
    font-size: 1em;
    border-radius: 25px;
    border: 1px solid #34579B;
    background-color: white;
    color: #34579b;
    min-width: 13.5em;
    min-height: 3em;
  }



  .home_2b {
    min-height: 35em;
  }

  .home_but_blue_desk {
    display: inline-block;
    font-family: 'visbycf_medium';
    font-size: .9em;
    border-radius: 25px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    min-width: 13em;
    min-height: 2.2em;
  }

}




@media (min-width:2560px) {



  .home_but_blue_desk {
    font-size: 1em;
    border-radius: 45px;
  }
}

.contenedor {

  max-width: 80rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  >img {
    object-fit: scale-down;
    max-width: 100%;
  }

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;

    // background-color: black;
    // width: 100%;
    padding: 0rem 4rem;
    gap: 1rem;

    >h2 {
      color: s.$darkBlue;
      font-size: s.$font-size-large;
      // text-align: center;
      line-height: 1.5;
    }

    >h4 {
      // max-width: 90%;
      color: s.$darkGray;
      font-size: s.$font-size-medium;
      line-height: 1.5;
    }

    * button {
      @include m.button();
    }

    @include m.tablet {
      padding: 0rem 2rem;

      // width: 60%;
      >h2 {
        font-size: s.$font-size-medium;
      }

      >h4 {
        font-size: s.$font-size-small + 0.1em;
        // max-width: 70%;
      }

    }

    @include m.tablet-pro {
      padding: 2rem;

      // width: 60%;
      >h2 {
        font-size: s.$font-size-large;
      }

      >h4 {
        font-size: s.$font-size-medium;
        // max-width: 70%;
      }

    }
  }

  @include m.tablet {
    flex-direction: row;

    max-height: 40rem;
    align-items: start;

    >img {
      max-width: 50%;
      
      border-top-right-radius: 3rem;
      border-bottom-right-radius: 3rem;
      @include m.desktop {
        border-radius: 3rem;
      }
    }
  }
}